<template>
<v-container>

  <!-- Show on a Large device -->
  <v-container fluid full class="mt-5 hidden-sm-and-down">
    <v-row>
      <v-col cols="3" class="filterSession" >
         <v-layout>
            <v-col cols="6">
              <h6>Filter</h6>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-icon color="#81C24B">mdi-filter</v-icon>
            </v-col>
         </v-layout>

         <v-layout class="mt-5">
          <v-responsive max-width="auto">
            <v-text-field
              solo
              dense
              label="Search medication.. "
              append-icon="mdi-magnify"
              v-model="search"
              @keyup="handleSearch()"
            >
            </v-text-field>
          </v-responsive>
         </v-layout>

         <v-row class="mt-5">
          <v-layout>
          <h6>Product Categories</h6>
          </v-layout>
          <v-list class="overflow-auto" max-height="1600">
                <v-list-item v-for="item in categoryItems" :key="item.id">
                  <v-checkbox v-model="item.checked" :label="item.name"></v-checkbox>
                </v-list-item>
            </v-list>
         </v-row>
      </v-col>

      <v-col cols="9">
        <v-row wrap>
          <v-col md="6" >
            <h3 class="mt-2">Recent Products</h3>
          </v-col>
          
          <v-col md="6" class="text-right">
             <v-layout>
                <v-col md="9" class="text-left">
                  <v-autocomplete
                    name="retailerList"
                    color="#000000"
                    v-model="retailerUserId"
                    label="Search for a trusted pharmacy"
                    :items="retailerList"
                    item-text="companyName"
                    item-value="User.userId"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="3" class="text-right mt-2" >
                  <v-btn align="center" color="#000000"
                    style="color:#ffffff;" @click.prevent="findRetailerShop">
                      Find
                    <v-icon class="mr-2">
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-col>
             </v-layout>
                
          </v-col>
        </v-row>
         <v-row>
          <template v-if="isAuth && userRole">
            <v-col v-for="product in getRandomProducts(filteredList, 15)" :key="product.id" md="4">
              <v-sheet
                height="400"
                style="border-radius:10px;"
                elevation="3"
              >
                 
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" >
                  <!-- <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="font-size:13px;"><a v-if="product.needsPrescription == false" :to="'/product-detail/' + product.productId">{{ product.name }}</a></p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }} <span class="ml-5">Qty: {{product.quantity}}</span></p>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/product-detail/' + product.productId"
                        v-if="product.needsPrescription == false"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-cart-outline
                        </v-icon>
                        <span>Add to Cart</span>
                      </v-btn>
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/prescription/dashboard/en'"
                        v-if="product.needsPrescription == true"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-pill-multiple
                        </v-icon>
                        Prescription Drug
                      </v-btn>
                    </v-col>
                  </v-row>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else-if="isAuth && retailerRole">
            <v-col v-for="product in getRandomProducts(retailerProducts, 15)" :key="product.id" md="4">
              <v-sheet
                height="400"
                style="border-radius:10px;"
                elevation="3"
              >
                 
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" >
                  <!-- <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="font-size:13px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }} <span class="ml-5">Qty: {{product.quantity}}</span></p>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/product-detail/' + product.productId"
                        v-if="product.needsPrescription == false"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-cart-outline
                        </v-icon>
                        <span>Add to Cart</span>
                      </v-btn>
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/prescription/dashboard/en'"
                        v-if="product.needsPrescription == true"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-pill-multiple
                        </v-icon>
                        Prescription Drug
                      </v-btn>
                    </v-col>
                  </v-row>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else-if="isAuth && distributorRole">
            <v-col v-for="product in getRandomProducts(distributorProducts, 15)" :key="product.id" md="4">
              <v-sheet
                height="400"
                style="border-radius:10px;"
                elevation="3"
              >
                 
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" >
                  <!-- <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="font-size:13px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }} <span class="ml-5">Qty: {{product.quantity}}</span></p>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/product-detail/' + product.productId"
                        v-if="product.needsPrescription == false"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-cart-outline
                        </v-icon>
                        <span>Add to Cart</span>
                      </v-btn>
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/prescription/dashboard/en'"
                        v-if="product.needsPrescription == true"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-pill-multiple
                        </v-icon>
                        Prescription Drug
                      </v-btn>
                    </v-col>
                  </v-row>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else>
            <v-col v-for="product in getRandomProducts(filteredList, 15)" :key="product.id" md="4">
              <v-sheet
                height="450"
                style="border-radius:10px;"
                elevation="3"
              >
                 
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" >
                  <!-- <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="font-size:13px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }} <span class="ml-5">Qty: {{product.quantity}}</span></p>
                  <p style="font-size:12px;">Product Description: {{ getProductContent(product) }}</p>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/product-detail/' + product.productId"
                        v-if="product.needsPrescription == false"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-cart-outline
                        </v-icon>
                        <span>Add to Cart</span>
                      </v-btn>
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/prescription/dashboard/en'"
                        v-if="product.needsPrescription == true"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-pill-multiple
                        </v-icon>
                        Prescription Drug
                      </v-btn>
                    </v-col>
                  </v-row>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>
        </v-row>

        <v-row class="my-10 d-flex justify-center">
              <v-col cols="12" md="8" lg="6">
                <v-pagination
                  v-if="totalPages > 1"
                  v-model="currentPage"
                  :length="totalPages"
                  color="gold"
                ></v-pagination>
              </v-col>
        </v-row>
        
      </v-col>
    </v-row>
  </v-container>

  <!-- SHow on a small device -->
  <v-container class="productContainerSm hidden-md-and-up">
        <v-row wrap>
          <v-col cols="12">  
            <h3>Recent Products</h3> 
          </v-col>
          <v-col cols="12"> 
            <v-text-field
                    solo
                    rounded
                    dense
                    v-model="search"
                    label="Search medication.. "
                    append-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
        <v-col col="12" md="12" class="text-right">
             <v-layout>
                <v-col col="9" class="text-left">
                  <v-autocomplete
                    name="retailerList"
                    color="#000000"
                    v-model="retailerUserId"
                    label="Search a pharmacy"
                    :items="retailerList"
                    item-text="companyName"
                    item-value="User.userId"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col col="3" class="text-right mt-2" >
                  <v-btn align="center" color="#000000"
                    style="color:#ffffff;" @click.prevent="findRetailerShop">
                      Find
                    <v-icon class="mr-2">
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-col>
             </v-layout>
          </v-col>
        </v-row>
        <v-row class="mb-5">
          <template v-if="isAuth && userRole">
            <v-col v-for="product in filteredList" :key="product.id" cols="6">
              <v-lazy
                v-model="isActive"
                :options="{
                  threshold: .5
                }"
                min-height="200"
                transition="fade-transition"
              >
              <v-sheet
                height="390"
                style="background-color:#FAFAFA;border-radius:10px;"
                elevation="3"
              >
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:340px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                <div class="mt-3 ml-3">
                  <!-- <h5 style="color:#81c24B;">
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  
              
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      color="#000000"
                      style="color:#ffffff;"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
              </v-lazy>
            </v-col>
          </template>

          <template v-if="isAuth && retailerRole">
            <v-col v-for="product in retailerProducts.slice(0,20)" :key="product.id" cols="6">
              <v-sheet
                height="390"
                style="background-color:#FAFAFA;border-radius:10px;"
                elevation="3"
              >
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:340px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                <div class="mt-3 ml-3">
                  <!-- <h5 style="color:#81c24B;">
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  
              
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      color="#000000"
                      style="color:#ffffff;"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-if="isAuth && distributorRole">
            <v-col v-for="product in distributorProducts.slice(0,20)" :key="product.id" cols="6">
             <v-sheet
                height="390"
                style="background-color:#FAFAFA;border-radius:10px;"
                elevation="3"
              >
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:340px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                <div class="mt-3 ml-3">
                  <!-- <h5 style="color:#81c24B;">
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  
              
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      color="#000000"
                      style="color:#ffffff;"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>

          <template v-else>
            <v-col v-for="product in getRandomProducts(filteredList, 20)" :key="product.id" cols="6">
              <v-sheet
                height="390"
                style="background-color:#FAFAFA;border-radius:10px;"
                elevation="3"
              >
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:340px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                <div class="mt-3 ml-3">
                  <!-- <h5 style="color:#81c24B;">
                    {{ systemSetting.systemCurrency }}
                    {{ product.price }}
                  </h5> -->
                  <p style="height:40px;font-size:14px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }}</p>
                  
              
                  <p>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      color="#000000"
                      style="color:#ffffff;"
                      :to="'/product-detail/' + product.productId"
                      v-if="product.needsPrescription == false"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add to Cart
                    </v-btn>
                    <v-btn
                      align="center"
                      class="buttonStyle"
                      :to="'/prescription/dashboard/en'"
                      v-if="product.needsPrescription == true"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription Drug
                    </v-btn>
                  </p>
                  
                </div>
              </v-sheet>
            </v-col>
          </template> 
        </v-row>

        <v-row class="my-10 d-flex justify-center">
              <v-col cols="12" md="8" lg="6">
                <v-pagination
                  v-if="totalPages > 1"
                  v-model="currentPage"
                  :length="totalPages"
                ></v-pagination>
              </v-col>
        </v-row>
  </v-container>
</v-container> 
</template>

<script>
export default {
  name: "Products",

  data: () => ({
    allProducts: [],
    productLists: [],
    retailerProducts:[],
    distributorProducts:[],
    search: '',
    systemSetting: {
      systemCurrency: "₦",
    },
    isActive: false,
    isAuth: null,
    userId:"",
    userRole:null,
    manufactureRole:null,
    distributorRole:null,
    retailerRole:null,
    categoryItems: null,
    limit:0,
    totalPages:0,
    maxVisibleButtons:"",
    loading: false,
    next:0,
    retailerList: [],
    retailerUserId:'',
  }),

  created() {
    this.getRetailers();
    this.isAuth = this.$auth.isAuthenticated();
    this.setAuthenticatedUser();
    this.getProductCat();
    this.getProducts();
  },

  computed: {
    filteredList() {
      return this.productLists.filter(product => {
        return product.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    currentPage: {
      get() {
        return this.next || 1;
      },
      set(value) {
        this.next = value;
      },
    },

  },

  methods: {
    getRetailers() {
      this.$http
        .get(`${process.env.VUE_APP_URL}retailers`)
        .then((response) => {
          this.retailerList = response.data.data;
        });
    },
    getRandomProducts(arr, num) {
        const shuffled = arr.slice().sort(() => Math.random() - 0.5);
        return shuffled.slice(0, num);
    },

    getProductContent(product) {
      let body = this.stripTags(product.description);
      return body.length > 60 ? body.substring(0, 60) + "..." : body;
    },

    stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },

    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          if (response.data.data.User.Role.name == "User") {
            this.userRole = true;
          }

          if (response.data.data.User.Role.name == "Manufacturer") {
            this.manufactureRole = true;
          }

          if (response.data.data.User.Role.name == "Distributor") {
            this.distributorRole = true;
          }

          if (response.data.data.User.Role.name == "Retailer") {
            this.retailerRole = true;
          }

        });
    },

    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          this.limit = response.data.data.limit;
          this.totalPages = response.data.data.totalPages;
          response.data.data.rows.forEach(element => {
            if(element.User.Role.name == "Retailer"){
              this.productLists.push(element);
            }
            if(element.User.Role.name == "Distributor"){
              this.retailerProducts.push(element);
            }
            if(element.User.Role.name == "Manufacturer"){
              this.distributorProducts.push(element);
            }
          });
        });
    },

    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categoryItems = response.data.data;
        });
    },

    findRetailerShop() {
      this.$router.push({
              path: "/store/0/" + this.retailerUserId,
      });
    }
  },
};
</script>

<style scoped>
span {
  text-transform: none;
}
.overflow-auto {
  overflow: auto;
}
.filterSession {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  max-height: auto;
  margin-top: 15px;
}
.priceStyle {
  width: 90px;
  height: auto;
  font-size: 12px;
  background-color: #000000;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}
.buttonStyle {
  margin-top: 10px;
  width: auto;
  height: auto;
  border-radius: 10px;
  font-size: 10px;
}
.productContainer {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 50px;
}
.productContainerSm {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}
.productImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 150px;
  margin-top:15px;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.theme--light .v-pagination .v-pagination__item--active {
  color: #5EB86A !important; /* Change this to your desired active color */
}


</style>
